import { render, staticRenderFns } from "./ChanelListSvg.vue?vue&type=template&id=af5df834&scoped=true&"
import script from "./ChanelListSvg.vue?vue&type=script&lang=js&"
export * from "./ChanelListSvg.vue?vue&type=script&lang=js&"
import style0 from "./ChanelListSvg.vue?vue&type=style&index=0&id=af5df834&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "af5df834",
  null
  
)

export default component.exports