<template>
    <!-- <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="8.80817" y="16.9991" width="30.3837" height="22.1927" stroke="white" stroke-width="1.61635"/>
        <path d="M20.9132 32.1326L20.9132 24.0775L28.0684 28.105L20.9132 32.1326Z" stroke="white" stroke-width="1.38544"/>
        <rect x="10.9474" y="11.7232" width="26.5263" height="1.84726" fill="#D9D9D9"/>
        <rect x="14.3158" y="8" width="19.7895" height="1.43675" fill="#D9D9D9"/>
    </svg> -->
    <svg :width="svgWnH ? svgWnH : 78" :height="svgWnH ? svgWnH : 78" viewBox="0 0 78 78" fill="none" xmlns="http://www.w3.org/2000/svg" class="channel_list_svg">
        <rect x="1.75" y="21.7154" width="74.5" height="54.5346" stroke="white" stroke-width="3.5"/>
        <path d="M31.2871 59.1456L31.2871 38.8663L49.3009 49.006L31.2871 59.1456Z" stroke="white" stroke-width="3"/>
        <rect x="7.1842" y="9.07513" width="64.6579" height="4.50269" fill="#D9D9D9"/>
        <rect x="15.3948" width="48.2368" height="3.50209" fill="#D9D9D9"/>
    </svg>
</template>
<script>
    export default {
        'name': 'ChanelListSvg',
        props: ['svgWnH']
    }
</script>
<style scoped>
    @media screen and (min-width: 1441px) {
        .channel_list_svg {
        width: 46px !important;
        height: 46px !important;
        }
    }

    @media screen and (max-width: 1441px) {
        .channel_list_svg {
            width: 30px !important;
            height: 30px !important;
        }
    }
</style>